<template>
  <TextImage
    :headline="$t('sichtbeton.headline')"
    :subline="$t('sichtbeton.subline')"
    :text="$t('sichtbeton.text') + '<br><a href=\'https://sichtbeton-forum.de\'>' + $t('global.read_more') + '</a>'"
    image="/assets/images/bkamt.jpg"
  />
</template>
<script>
import TextImage from "@/components/TextImage.vue";
export default {
  components: {
    TextImage,
  },
};
</script>
<style lang="scss" scoped></style>
