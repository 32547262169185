<template>
  <div id="gutachten">
    <div v-if="!viewedGutachten">
      <section>
        <div class="container">
          <h1 :data-sub="$t('gutachten.subline')">
            {{ $t("gutachten.headline") }}
          </h1>
          <p class="block" v-html="$t('gutachten.text')"></p>
        </div>
      </section>

      <section class="highlighted">
        <div class="container">
          <div class="chips">
            <input :placeholder="$t('global.suchen')" v-model="search" />
            <div
              class="chip"
              v-for="chip in chips"
              :key="chip"
              :class="{ active: chip == activeChip }"
              @click="() => { activeChip = chip; search = ''}"
            >
              <span>{{ chip }}</span>
            </div>
          </div>

          <div class="row noreduce">
            <div
              class="col-lg-4 col-md-6 col-sm-12"
              v-for="(service, i) in getVisibleGutachten()"
              :key="i"
            >
              <ServiceCard
                :image="service.image"
                :title="service.title"
                :text="service.text"
                :to="'sachverstaendigen-gutachten/' + service.path"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
    <TextImage
      v-else
      :headline="viewedGutachten.title"
      :subline="viewedGutachten.category"
      :text="viewedGutachten.text"
      :image="viewedGutachten.image"
    />
  </div>
</template>
<script>
import ServiceCard from "@/components/ServiceCard";
import TextImage from "@/components/TextImage.vue";

export default {
  metaInfo: {
    meta: [
      { name: "keywords", content: "test" }
    ]
  },
  components: {
    ServiceCard,
    TextImage,
  },
  beforeMount() {
    this.gutachten = this.$t("gutachten.gutachten");
    for (let ga of this.gutachten) {
      ga.path = ga.title.toLowerCase().replaceAll(" ", "_");
    }

    let viewedItemTitle = this.$route.params.title;
    if (viewedItemTitle) {
      let viewedGutachten = this.gutachten.filter(
        (ga) => ga.path == viewedItemTitle
      );
      console.log(viewedGutachten);
      if (viewedGutachten.length == 1) {
        this.viewedGutachten = viewedGutachten[0];
      }
    }

    if(this.viewedGutachten) {
      console.log("title", this.viewedGutachten.title)
      document.title = document.title += " " + this.viewedGutachten.title
    }

    this.all_selector = this.$t("global.alle");
    this.chips.push(this.all_selector);
    this.activeChip = this.all_selector;

    for (let ga of this.gutachten) {
      if (!this.chips.includes(ga.category)) {
        this.chips.push(ga.category);
      }
    }

    this.setImage(0, "/assets/images/evaluations/fassaden.jpg");
    this.setImage(1, "/assets/images/evaluations/schimmelpilz.jpg");
    this.setImage(2, "/assets/images/evaluations/schiedsgutachten.jpg");
    this.setImage(3, "/assets/images/evaluations/bodenbeläge.jpg");
    this.setImage(4, "/assets/images/evaluations/baustoffe.jpg");
    this.setImage(5, "/assets/images/evaluations/beton.jpg");
    this.setImage(6, "/assets/images/evaluations/plattenbau.jpg");
    this.setImage(7, "/assets/images/evaluations/legionellen.jpg");
    this.setImage(8, "/assets/images/evaluations/grundwasser.jpg");
    this.setImage(9, "/assets/images/evaluations/balkon_gutachten.jpg");
    this.setImage(10, "/assets/images/evaluations/baudenkmal.jpg");

    this.setImage(11, "/assets/images/evaluations/hochbau.jpg");
    this.setImage(12, "/assets/images/evaluations/full_service.jpg");
    this.setImage(13, "/assets/images/evaluations/schwimmbäder.jpg");
    this.setImage(
      14,
      "/assets/images/evaluations/stock/sicherheitsarchitektur.jpg"
    );
    this.setImage(15, "/assets/images/evaluations/windkraftanlagen.jpg");
    this.setImage(16, "/assets/images/evaluations/dachsanierung.jpg");

    this.setImage(17, "/assets/images/evaluations/barrierefrei.jpg");
    this.setImage(18, "/assets/images/evaluations/molkereien.jpg");
    this.setImage(19, "/assets/images/evaluations/ruettelbelaege.jpg");
    this.setImage(20, "/assets/images/evaluations/generalplanung.jpg");
    this.setImage(21, "/assets/images/evaluations/spritzbeton.jpg");
    this.setImage(22, "/assets/images/evaluations/balkon_instandsetzung.jpg");
    this.setImage(23, "/assets/images/evaluations/bauschadensbericht.jpg");

    this.setImage(24, "/assets/images/evaluations/wellasbest.jpg");
    this.setImage(25, "/assets/images/evaluations/souterrain.jpg");
    this.setImage(26, "/assets/images/evaluations/risse.jpg");
    this.setImage(27, "/assets/images/evaluations/gipsputz.jpg");
    this.setImage(28, "/assets/images/evaluations/stock/tiefgarage.jpg");
    this.setImage(29, "/assets/images/evaluations/stock/sichtbetonmöbel.jpg");
    this.setImage(30, "/assets/images/evaluations/passivhaeuser.jpg");
    this.setImage(31, "/assets/images/evaluations/instandsetzungsplanung.jpg");
    this.setImage(32, "/assets/images/evaluations/fundamente.jpg");
    this.setImage(33, "/assets/images/evaluations/tragwerksplanung.jpg");
    this.setImage(34, "/assets/images/evaluations/stock/energieberatung.jpg");
    this.setImage(35, "/assets/images/evaluations/thermographie.jpg");
    this.setImage(36, "/assets/images/evaluations/brandschutz.jpg");
    this.setImage(37, "/assets/images/evaluations/wdvs.jpg");
    this.setImage(38, "/assets/images/evaluations/stock/langzeitmessungen.jpg");
    this.setImage(39, "/assets/images/evaluations/stock/bauschäden.jpg");
    this.setImage(40, "/assets/images/evaluations/stock/beweissicherung.jpg");
    this.setImage(
      41,
      "/assets/images/evaluations/stock/versicherungsgutachten.jpg"
    );
    this.setImage(42, "/assets/images/evaluations/qualitaetskontrolle.jpg");
    this.setImage(43, "/assets/images/evaluations/spanien.jpg");
    this.setImage(44, "/assets/images/evaluations/ibiza.jpg");
    this.setImage(45, "/assets/images/evaluations/mallorca.jpg");
    this.setImage(46, "/assets/images/evaluations/stock/rom.jpg");
    this.setImage(47, "/assets/images/evaluations/algier.jpg");

    this.setImage(48, "/assets/images/evaluations/stock/london.jpg");
    this.setImage(49, "/assets/images/evaluations/mexiko.jpg");

    this.setImage(50, "/assets/images/evaluations/abdichtung.jpg");
  },
  methods: {
    setImage(id, imageUrl) {
      for (let item of this.gutachten) {
        if (item.id == id) {
          item.image = imageUrl;
        }
      }
    },
    getVisibleGutachten() {
      return this.gutachten.filter((ga) => {
        if(this.search) {
          this.activeChip = this.all_selector;
          return (ga.title.toLowerCase().includes(this.search.toLowerCase()))
        }
        else if(ga.category == this.activeChip || this.activeChip == this.all_selector) {
          return true;
        }
        else {
          return false;
        }
      });
    },
  },
  data() {
    return {
      search: "",
      gutachten: [],
      chips: [],
      activeChip: "",
      all_selector: "",
      viewedGutachten: undefined,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

#gutachten {
  padding-bottom: 0;
  display: flex;
  > div {
    width: 100%;
  }
  section {
    margin: 0 auto;
  }
  h1 {
    margin-top: 75px;
  }
}

.chips {
  display: flex;
  //justify-content: center;
  margin-bottom: 50px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  input {
    margin-left: auto;
    margin-right: 20px;
    border-radius: 100px;
    border: 1px solid #ccc;
    padding: 0 20px;
    color: #666;
    &:focus {
      outline: none !important;
      border-color: $color_accent;
    }
  }

  .chip {
    $highlight: $color_accent;
    background-color: white;
    color: $highlight;
    text-transform: capitalize;
    border-radius: 100px;
    padding: 10px 20px;
    margin-right: 20px;
    border: 1px solid $highlight;
    transition: color 0.5s ease, background-color 0.5s ease;
    &.active,
    &:hover {
      color: white;
      background-color: $highlight;
    }
    &:hover {
      cursor: pointer;
    }
    &:last-child {
      margin-right: auto;
    }
  }
}
</style>
