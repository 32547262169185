<template>
    <div>
        <section>
            <div class="container">
                <h1 :data-sub="$t('about.subline')">{{ $t('about.headline') }}</h1>
                <div class="row noreduce">
                    <div class="col-lg-6">
                        <p v-html="$t('about.intro')"></p>
                    </div>
                    <div class="col-lg-6">
                        <img src="/assets/images/buero.jpeg" alt="büro"/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

</style>