<template>
  <div class="card">
    <img :src="image" :style="{ height: image_height + 'px' }" alt="card image"/>
    <div class="icon" v-if="icon">
      <i :class="icon"></i>
    </div>
    <div v-if="icon_text" class="icon_text">
      <span>{{ icon_text }}</span>
    </div>
    <div class="content">
      <h4>{{ title }}</h4>
      <p v-html="text"></p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    text: String,
    icon: String,
    icon_text: String,
    image: String,
    image_height: Number
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.card {
  width: 100%;
  img {
    height: 260px;
    object-fit: cover;
  }
  h4 {
    font-size: 1.25rem;
    color: #000;
  }
  .content {
    padding: 0 30px;
    p {
        text-align: left;
        padding-bottom: 20px;
    }
  }
  .icon {
    background-color: $color_accent;
    height: 60px;
    width: 60px;
    display: flex;
    color: white;

    margin: -30px 0 30px 30px;

    i {
      margin: auto;
      font-size: 1.5rem;
    }
  }
  .icon_text {
    background-color: $color_accent;
    display: inline-block;
    margin: -17px auto 30px 30px;
    padding: 5px 25px;
    span {
      color: white;
    }
  }
}
</style>
