<template>
  <div>
    <section>
      <div class="container">
        <h1 data-sub="der IGS-Schulz">Leitende Mitarbeiter</h1>

        <div class="card">
          <div class="row noreduce">
            <div class="col-lg-3">
              <img src="/assets/images/people/schulz.jpg" alt="joachim schulz"/>
            </div>

            <div class="text-content col-lg-9">
              <h3>
                Dipl.-Ing.
                <span>Joachim Schulz</span>
                <span class="sub"> Geschäftsführer</span>
                <br />
                <span class="sub"
                  >Bausachverständiger, Beratender Ingenieur + Architekt</span
                >
              </h3>
              <div class="row">
                <div size="12" class="col-lg-12 col">
                  <b
                    >Öffentlich bestellter und vereidigter (ö.b.u.v.)
                    Sachverständiger</b
                  >
                  <p>
                    Für die Sachgebiete Schäden an Gebäuden, insb.
                    Beton-Schäden, Beton-Instandsetzung, WU-Beton, Sichtbeton;
                    vereidigt durch die IHK Berlin, Fasanenstraße 45, 10623
                    Berlin.
                    <br />Mit Hochschulabschluss für: Architektur und
                    konstruktiven Ingenieurbau.
                  </p>
                  <p>
                    Autor von mehreren
                    <a href="/lehre_und_publikationen/fachbuecher" class=""
                      >Fachbüchern</a
                    >.
                  </p>
                </div>
                <div size="12" class="col-md-12 col-lg-6 col">
                  <b>Mitglied in den Verbänden</b>
                  <ul>
                    <li>Architektenkammer Berlin: 09436</li>
                    <li>Baukammer Berlin: P0094</li>
                    <li>Verband der Vereidigten Sachverständigen</li>
                    <li>Industrie- und Handelskammer</li>
                    <li>Architektenkammer IBIZA: N COAIB: 950210</li>
                    <li>
                      COL-LEGI OFFICIAL D’ARQUITECTES ILLES – BALEARS: Num Col.
                      legiat: 515681
                    </li>
                    <li>Energieberater „dena“</li>
                    <li>Sachverständigenausschuss BK-Berlin</li>
                    <li>Güteschutzgemeinschaft Betoninstandsetzung</li>
                  </ul>
                </div>
                <div size="12" class="col-md-12 col-lg-6 col">
                  <b>Weiteres</b>
                  <ul>
                    <li>
                      Lehrbeauftragter (ehemals) an Berliner Hochschule für
                      Technik (ehemals Beuth / TFH) für Baustoffe,
                      Baukonstruktion, Bauschäden, Sichtbeton
                    </li>
                    <li>Dozent</li>
                    <li>Moderator in der Dokuserie „Baupfusch“ von SAT1</li>
                    <li>Gastvorträge u.a.</li>
                    <li class="sublink">
                      <ul>
                        <li>Universatiy of Applied Sciences ERFURT</li>
                        <li>Bauhaus-Universität WEIMAR</li>
                        <li>Universität HANNOVER</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="row noreduce">
            <div class="col-lg-3">
              <img src="/assets/images/people/herzig.jpg" alt="matthias herzig"/>
            </div>
            <div class="text-content col-lg-9">
              <h3>
                M.Sc.
                <span>Matthias Herzig</span>
                <span class="sub"> Büroleiter</span>
                <br />
                <span class="sub">Architekt, Sachverständiger für Schäden an Gebäuden</span>
              </h3>
              <div class="row">
                <div size="12" class="col-lg-12 col">
                  <b>Architekt</b>
                  <p>Mit Hochschulabschluss für: Architektur.</p>
                </div>
                <div size="12" class="col-md-12 col-lg-6 col">
                  <b>Mitglied in den Verbänden</b>
                  <ul>
                    <li>Architektenkammer Berlin: 15681</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
.card {
  margin-bottom: 50px;
}
.text-content {
  padding: 30px;
  text-align: left;
  h3 {
    color: $color_secondary;
    span {
      color: $color_main;
      &.sub {
        font-size: 1rem;
        color: #666;
      }
    }
  }
  .col {
    margin-bottom: 10px !important;
  }
}
</style>
