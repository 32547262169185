<template>
  <div class="container">
    <section>
      <h1>{{ $t("objektplanung.headline") }}</h1>

      <div class="row noreduce mt-50">
        <div class="col-lg-4 col-md-6" v-for="(item, i) in items" :key="i">
          <img class="shadow" :src="item.image" alt="gebäude"/>
          <h2>{{ item.title }}</h2>
          <p v-html="item.text"></p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data() {
    return {
      items: [],
    };
  },
  beforeMount() {
    this.items = this.$t("objektplanung.items");
    this.items[0].image = "/assets/images/instandsetzung.jpg";
    this.items[1].image = "/assets/images/altbau.jpg";
    this.items[2].image = "/assets/images/denkmalschutz.jpg";
    this.items[3].image = "/assets/images/dachgeschoss.jpg";
    this.items[4].image = "/assets/images/betonbau.jpg";
    this.items[5].image = "/assets/images/betoninstandsetzung.jpg";
  },
};
</script>
<style lang="scss" scoped>
img {
  height: 300px;
  margin-bottom: 20px;
}
</style>
