import { render, staticRenderFns } from "./Mitarbeiter.vue?vue&type=template&id=df84373a&scoped=true&"
import script from "./Mitarbeiter.vue?vue&type=script&lang=js&"
export * from "./Mitarbeiter.vue?vue&type=script&lang=js&"
import style0 from "./Mitarbeiter.vue?vue&type=style&index=0&id=df84373a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df84373a",
  null
  
)

export default component.exports