<template>
  <div id="app">
    <Navigation :onLangChange="langChanged" />
    <div class="app-content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  components: {
    Navigation,
    Footer,
  },
  mounted() {
    console.log("mounted");
    const images = document.querySelectorAll("img");

    let attribution = {
      "trittschall": [
        "Luis Quintero",
        "https://www.pexels.com/de-de/foto/frau-die-schwarze-wildleder-stilettos-tragt-2044228/",
      ],
      "altbau": ["Emre Can Acer", "https://www.pexels.com/de-de/foto/braunes-gebaude-2079189/"],
      "betonbau": ["Life Of Pix", "https://www.pexels.com/de-de/foto/auf-der-baustelle-fliessender-zement-2469/"],
      "bkamt": ["KleeKarl", "https://pixabay.com/de/photos/berlin-kanzleramt-platz-52254/"],
      "brandschutz": ["Oluwaseun Duncan", "https://www.pexels.com/de-de/foto/roter-schlauchaufbewahrungsgriff-189466/"],
      "denkmalschutz": ["Tom Fisk", "https://www.pexels.com/photo/brown-and-beige-concrete-house-with-attic-5361423/"],
      "grundriss": ["Maksim Romashkin", "https://www.pexels.com/de-de/foto/bau-lineal-manner-bleistift-13812421/"],
      "instandsetzung": ["Min An", "https://www.pexels.com/de-de/foto/zwei-personen-auf-truss-tower-1629184/"],
      "planung": ["Karol D", "https://www.pexels.com/de-de/foto/nahaufnahme-einer-seilbahn-323645/"],
      "measuring": ["JESHOOTS.com", "https://www.pexels.com/de-de/foto/grundriss-auf-dem-tisch-834892/"],
      "meeting": ["Pixabay", "https://www.pexels.com/de-de/foto/weisser-holztisch-mit-stuhlen-set-416320/"],
      "worker": ["Kateryna Babaieva", "https://www.pexels.com/de-de/foto/mann-mit-orangefarbenem-schutzhelm-2760241/"],
      "ausland_2": ["Lucas Klein", "https://www.pexels.com/de-de/foto/gebaude-fenster-blauer-himmel-wohnung-13423436/"],
      "desk": ["Pixabay", "https://www.pexels.com/de-de/foto/drei-personen-sitzen-neben-dem-tisch-416405/"],
      "dämmung": ["Tim Reckmann", "https://www.flickr.com/photos/115225894@N07/50100645252"],
      "bauschäden": ["Monstera", "https://www.pexels.com/de-de/foto/textur-mauer-abstrakt-weiss-7794436/"],
      "beweissicherung": ["Md Iftekhar Uddin Emon", "https://www.pexels.com/de-de/foto/graustufenfotografie-der-person-die-schwarze-dslr-kamera-halt-403495/"],
      "energieberatung": ["Pexels", "https://pixabay.com/de/photos/licht-architektur-lampe-idee-1283795/"],
      "langzeitmessungen": ["Lukas", "https://www.pexels.com/de-de/foto/person-halt-stift-der-auf-grafik-zeigt-590020/"],
      "london": ["Pixabay", "https://www.pexels.com/de-de/foto/londoner-stadtbild-460672/"],
      "rom": ["Davi Pimentel", "https://www.pexels.com/de-de/foto/kolosseum-rom-italien-2064827/"],
      "sicherheitsarchitektur": ["Pixabay", "https://www.pexels.com/de-de/foto/graustufenfoto-von-stacheldraht-274886/"],
      "sichtbetonmöbel": ["Rodolfo Quirós", "https://www.pexels.com/de-de/foto/selektiver-fokus-fotografie-zement-2219024/"],
      "versicherungsgutachten": ["Ivan Samkov", "https://www.pexels.com/de-de/foto/hand-schreibtisch-laptop-notizbuch-4240497/"],
      "betoninstandsetzung": ["Pixabay", "https://www.pexels.com/de-de/foto/graues-betongebaude-7931/"]
    };

    for (var i = 0; i < images.length; i++) {
      let image = images[i];
      let filename = image.src.split("/").pop().split(".")[0];
      let attr = attribution[filename];
      if (!attr) {
        console.log("no attr data found for", filename);
      } else {
        $("#attr").append(`<a href="${attr[1]}">${attr[0]}</a> `);
      }
    }
  },
  beforeMount() {
    let locale = this.$route.query.lang;
    let cookieLocale = this.getLangCookie();

    if (!cookieLocale) {
      cookieLocale = undefined;
    }

    if (locale == undefined && cookieLocale) {
      this.$router.replace({ query: { lang: cookieLocale } });
      locale = cookieLocale;
    }
    if (locale == undefined && cookieLocale == undefined) {
      this.$router.replace({ query: { lang: this.$i18n.locale } });
      locale = this.$i18n.locale;
      this.setLangCookie(this.$i18n.locale);
    }

    if (locale && locale != cookieLocale) {
      this.setLangCookie(locale);
    }
    if (locale && locale != this.$i18n.locale) {
      this.$i18n.locale = locale;
    }

    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
  },
  methods: {
    langChanged(locale) {
      this.$router.replace({ query: { lang: locale } });
      window.location.reload();
    },
    setLangCookie(locale) {
      document.cookie = "lang=" + locale;
    },
    getLangCookie() {
      let lang = document.cookie
        .split(";")
        .filter((el) => el.includes("lang"))[0];
      if (!lang) {
        return "";
      }
      return lang.split("=").pop();
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/variables.scss";
body {
  margin: 0;
}

html {
  overflow-x: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #ededed;
}
.app-content {
  min-height: 100vh;
  padding-top: 13vh;
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    flex-grow: 2;
    width: 100%;
    padding-bottom: 50px;
  }
}
</style>
