<template>
  <div>
    <section>
      <div class="container">
        <h1 :data-sub="$t('seminare.subline')">
          {{ $t("seminare.headline") }}
        </h1>
        <div class="row noreduce">
          <div class="col-lg-6">
            <p class="block" v-html="$t('seminare.intro')"></p>
            <a href="/lehre_und_publikationen/fachbuecher">{{
              $t("global.here")
            }}</a>
          </div>
          <div class="col-lg-6">
            <img src="/assets/images/stock/meeting.jpg" alt="meetingroom"/>
          </div>
        </div>
        <div class="row noreduce">
          <div
            class="col-lg-6 seminar"
            v-for="(seminar, i) in seminare"
            :key="i"
          >
            <h4>{{ seminar.title }}</h4>
            <p v-html="seminar.description"></p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  beforeMount() {
    this.seminare = this.$t("seminare.seminare");
  },
  data() {
    return {
      seminare: [],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
.seminar {
  &:nth-child(odd) {
    p,
    h4 {
      text-align: right;
    }
  }
}

@media(max-width: 992px) {
  .seminar {
    p, h4 {
      text-align: left !important;
    }
  }
}
</style>
