<template>
  <nav>
    <div class="container">
      <a href="/"><div class="logo"></div></a>

      <div class="wrapper">
        <div class="upper">
          <div class="social">
            <a href="https://www.facebook.com/IGSchulz-Bausachverst%C3%A4ndige-Berlin-Potsdam-IbizaMallorca-430931640321125/"><i class="fa-brands fa-facebook"></i></a>
            <a href="https://www.linkedin.com/in/igschulz/"><i class="fa-brands fa-linkedin"></i></a>
            <a href="https://www.instagram.com/igschulz/"><i class="fa-brands fa-instagram"></i></a>
          </div>
          <div class="languages">
            <img src="/assets/flags/de.svg" @click="switchLocale('de')" alt="de-flag" />
            <img src="/assets/flags/gb.svg" @click="switchLocale('en')" alt="en-flag"/>
            <img src="/assets/flags/es.svg" @click="switchLocale('es')" alt="es-flag"/>
          </div>
        </div>

        <div class="lower desktop">
          <ul>
            <li v-for="menuitem in menu" :key="menuitem.title">
              <a v-if="menuitem.to" :href="menuitem.to">{{
                menuitem.title
              }}</a>
              <div class="submenuwrapper" v-else>
                <span>{{ menuitem.title }}</span>
                <ul class="submenu">
                  <li
                    v-for="submenuitem in menuitem.submenu"
                    :key="submenuitem.title"
                  >
                    <a :href="submenuitem.to"
                      ><p>
                        <i class="fa fa-chevron-right"></i
                        ><span>{{ submenuitem.title }}</span>
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        <div class="lower mobile">
          <div class="icon" @click="mobile.nav_open = !mobile.nav_open">
            <i v-if="mobile.nav_open" class="fa fa-close"></i>
            <i v-else class="fa fa-bars-staggered"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="mobile_menu" :class="{ closed: !mobile.nav_open }">
      <div class="menuitem" v-for="menuitem in menu" :key="menuitem.title">
        <a v-if="menuitem.to" :href="menuitem.to">{{
          menuitem.title
        }}</a>
        <div class="submenuwrapper" v-else :class="{ closed: !menuitem.open }">
          <span v-on:click="menuitem.open = !menuitem.open">{{
            menuitem.title
          }}</span>
          <div class="submenu">
            <div
              class="linkwrapper"
              v-for="submenuitem in menuitem.submenu"
              :key="submenuitem.title"
            >
              <a :href="submenuitem.to">
                <i class="fa fa-chevron-right"></i>
                {{ submenuitem.title }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  props: {
    onLangChange: Function
  },
  methods: {
    switchLocale(locale) {
      this.onLangChange(locale);
    },
  },
  mounted() {
    this.menu = [
      //{ title: "IGS Berlin", to: "/" },

      {
        title: this.$t("navigation.leistungen"),
        open: false,
        submenu: [
          { title: this.$t("navigation.leistungen_t1"), to: "/leistungen/sachverstaendigen-gutachten" },
          { title: this.$t("navigation.leistungen_t2"), to: "/leistungen/bauberatung-baubegleitung" },
          { title: this.$t("navigation.leistungen_t3"), to: "/leistungen/beweissicherung" },
          { title: this.$t("navigation.leistungen_t4"), to: "/leistungen/technische-due-diligence" },
          { title: this.$t("navigation.leistungen_t5"), to: "/leistungen/beratung-zur-objektplanung" },
          { title: this.$t("navigation.leistungen_t6"), to: "/leistungen/sichtbeton" },
          { title: this.$t("navigation.leistungen_t7"), to: "/leistungen/dachraumausbau" },
        ],
      },
      {
        title: this.$t("navigation.lehre_publikationen"),
        open: false,
        submenu: [
          { title: this.$t("navigation.lehre_publikationen_t1"), to: "/lehre_und_publikationen/fachbuecher" },
          { title: this.$t("navigation.lehre_publikationen_t2"), to: "/lehre_und_publikationen/fachartikel" },
          { title: this.$t("navigation.lehre_publikationen_t3"), to: "/lehre_und_publikationen/seminare" },
          { title: this.$t("navigation.lehre_publikationen_t4"), to: "/lehre_und_publikationen/das-grundeigentum" },
        ],
      },
      {
        title: this.$t("navigation.unternehmen"),
        open: false,
        submenu: [
          { title: this.$t("navigation.unternehmen_t1"), to: "/unternehmen/ueber_uns" },
          { title: this.$t("navigation.unternehmen_t2"), to: "/unternehmen/stellen" },
          { title: this.$t("navigation.unternehmen_t3"), to: "/unternehmen/mitarbeiter" },
        ],
      },
      {
        title: this.$t("navigation.referenzen"),
        to: "/referenzen",
        /*open: false,
        submenu: [
          { title: "Büro", to: "/" },
          { title: "Hotel", to: "/" },
          { title: "Industrie", to: "/" },
          { title: "Wohnungsbau", to: "/" },
          { title: "Öffentliche Bauten", to: "/" },
          { title: "Forschung", to: "/" },
          { title: "Sonderbauten", to: "/" },
          { title: "Denkmalschutz", to: "/" },
        ],*/
      },
      //{ title: this.$t("navigation.kontakt"), to: "/kontakt" },
    ];
  },
  data() {
    return {
      mobile: {
        nav_open: false,
      },
      menu: [],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

nav {
  position: fixed;
  padding: 20px 0;
  width: 100vw;
  border-bottom: 1px solid #ccc;
  background-color: white;
  z-index: 101;
  top: 0;

  .container .wrapper .lower.mobile {
    display: none;
    margin-left: auto;
    .icon {
      font-size: 23px;
    }
  }

  .mobile_menu {
    position: absolute;
    display: none;
    width: 100vw;
    top: 50px;
    left: 0%;
    z-index: 100;
    background-color: white;
    text-align: left;
    transition: left 0.5s ease;
    &.closed {
      left: 100%;
    }

    .menuitem,
    .submenu a {
      border-bottom: 1px solid #ccc;
      padding: 10px 20px;
      display: block;
    }
    .menuitem span {
      padding-top: 0;
    }

    .submenuwrapper {
      &.closed {
        > span {
          border-bottom: none;
        }
        .linkwrapper {
          height: 0;
          border-color: transparent;
          border-bottom: none;
        }
      }
      .linkwrapper {
        display: flex;
        vertical-align: middle;
        height: 40px;
        overflow: hidden;
        //border-bottom: 1px solid #ccc;
        transition: height 0.5s ease, border-color 0.5s ease;
        &:last-child {
          border-bottom: none;
        }

        i {
          margin-right: 10px;
        }

        a {
          display: inline-block;
          width: 100%;
          padding: 0;
          margin: auto 0;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .container {
    display: flex;

    .logo {
      background-image: url("@/assets/images/logo/full.png");
      $height: 60px;
      height: $height;
      width: (592 / 133) * $height;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 10px 0;
    }
    .wrapper {
      position: relative;
      margin: auto 0 auto auto;
      .languages {
        img {
          &:hover {
            cursor: pointer;
          }
        }
      }
      .upper {
        display: flex;
        margin-bottom: 15px;
        .social {
          margin-left: auto;
        }

        a {
          color: $color_main;
          margin-right: 10px;
          font-size: 18px;
        }
        img {
          height: 15px;
          margin-left: 10px;
        }
      }
      .lower {
        display: flex;

        ul {
          display: flex;
          margin: auto 0 auto auto;
          padding: 0;
          li {
            position: relative;
            list-style: none;
            &:last-child a,
            span {
              margin-right: 0;
            }
            a,
            span {
              text-decoration: none;
              color: #666;
              height: 35px;
              margin: 0 15px;
              transition: color 0.15s ease;
              &:hover {
                cursor: pointer;
              }
            }
            a {
              color: $color_main;
              font-weight: bold;
            }
            .submenuwrapper {
              &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 100%;
                height: 52px;
                border-left: 2px solid $color_main;
              }
              &:hover {
                cursor: pointer;
                .submenu {
                  display: block !important;
                }
                > span {
                  color: $color_main;
                }
              }
            }
            .submenu {
              padding: 10px 20px;
              left: 0px;
              top: 49px;
              display: none !important;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;

              background-color: white;
              border: 1px solid #ccc;
              position: absolute;
              z-index: 100;
              display: flex;
              flex-direction: column;
              text-align: left;
              padding: 0;

              border-left: 2px solid $color_main;
              border-top: none;

              a {
                color: #666;
                font-weight: normal;
                margin: 0;

                p {
                  display: flex;
                  white-space: nowrap;
                  margin: 0;
                  padding: 12px 20px;
                  border-bottom: 1px solid #ccc;
                  &:hover {
                    i,
                    span {
                      color: $color_main;
                    }
                  }
                  i {
                    margin-right: 10px;
                  }
                  span {
                    transition: color 0.15s ease;
                    height: unset;
                    display: inline-block;
                    flex-grow: 2;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  nav .container .logo {
    display: none;
  }
  nav .submenuwrapper::after {
    height: 45px!important;
  }
}

@media (max-width: 768px) {
  nav {
    padding: 10px 20px;
    .mobile_menu {
      display: inline-block;
    }
    .container {
      padding: 0;
      position: relative;
      .logo {
        display: inline-block;
        background-image: url("@/assets/images/logo/logo_cutted.png");
        height: 20px;
        width: (215 / 55) * 20px;
        margin-right: 20px;
      }
      .wrapper {
        display: inline-flex;
        width: 100%;
        flex-direction: row;
        .upper {
          margin: auto 0;
        }
        .lower.mobile {
          display: flex;
        }
      }
    }
  }
  nav .desktop {
    display: none !important;
  }
}
</style>
