<template>
  <TextImage
    :headline="$t('techdd.headline')"
    :subline="$t('techdd.subline')"
    :text="$t('techdd.text')"
    image="/assets/images/techdd.jpeg"
  />
</template>
<script>
import TextImage from "@/components/TextImage.vue";
export default {
  components: {
    TextImage,
  },
};
</script>
<style lang="scss" scoped></style>
