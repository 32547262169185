<template>
  <div>
    <section>
      <div class="container">
        <h1 :data-sub="$t('jobs.subline')">{{ $t("jobs.headline") }}</h1>
        <p v-html="$t('jobs.intro')"></p>
        <b>{{ $t("jobs.cta") }}</b>
        <a href="mailto:info@igs-schulz.de"> info@igs-schulz.de</a>
        <div class="row noreduce">
          <div class="col-lg-12" v-for="(job, i) in $t('jobs.jobs')" :key="i">
            <div class="card">
              <div class="row noreduce">
                <div class="col-lg-4">
                  <h4>{{ job.name }}</h4>
                </div>
                <div class="col-lg-4">
                  <span>{{ job.area }}</span>
                </div>
                <div class="col-lg-4"></div>

                <div class="col-lg-4">
                  <b>{{ $t("jobs.angebot") }}</b>
                  <ul>
                    <li v-for="(angebot, j) in job.angebot" :key="'an_' + j">
                      {{ angebot }}
                    </li>
                  </ul>
                </div>
                <div class="col-lg-4">
                  <b>{{ $t("jobs.aufgaben") }}</b>
                  <ul>
                    <li v-for="(aufgabe, j) in job.aufgaben" :key="'au_' + j">
                      {{ aufgabe }}
                    </li>
                  </ul>
                </div>
                <div class="col-lg-4">
                  <b>{{ $t("jobs.profil") }}</b>
                  <ul>
                    <li v-for="(profil, j) in job.profil" :key="'p_' + j">
                      {{ profil }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.card {
  margin-top: 30px;
  padding: 50px;
  
  ul, b {
    text-align: left;
  }
  ul {
    margin-top: 15px;
  }
}
</style>
