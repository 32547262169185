<template>
  <footer>
    <div class="container">
      <div class="row noreduce">
        <div class="col-lg-3 col-md-6 contact ">
          <h3>Kontakt</h3>

          <p>
            <i class="fa fa-phone"></i>
            <span>Telefon:</span>
            <a href="tel:+49303009830">+49 (30) 300 98 30</a>
          </p>
          <p>
            <i class="fa fa-envelope"></i>
            <span>E-Mail:</span>
            <a href="mailto:info@igs-schulz.de">info@igs-schulz.de</a>
          </p>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3>Berlin</h3>
          <p>
            Ulmenallee 53<br />
            D-14050 Berlin<br />
            Deutschland / Germany<br />
          </p>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3>Potsdam</h3>
          <p>
            Tornowstraße 8<br />
            D-14473 Potsdam<br />
            Deutschland / Germany
          </p>
        </div>
        <div class="col-lg-3 col-md-6">
          <h3>Ibiza - Mallorca - Formentera</h3>
          <p>
            Carrer de s`Hort de sa Fruita 5,<br />
            Portal A <br />
            ES-07800 Ibiza / Spain
          </p>
        </div>
        <div class="col-lg-12">
            <p class="center">© {{ new Date().getFullYear() }} IGS Schulz | <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutzerklärung</a></p>
            <span class="cc">CC-Attribution der Bilder: <span id="attr"></span></span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

footer {
  padding: 50px 0 20px;
  background-color: $color_bold;

  .cc {
    color: #ddd;
  }
  p,
  h3 {
    color: white;
  }

  [class^="col-"] {
    display: flex;
    flex-direction: column;
  }

  p {
    display: flex;
    margin-bottom: 0;
    i {
      margin: auto 0;
      margin-right: 10px;
    }
    a {
      color: $color_secondary;
      margin-left: auto;
    }
  }
  p.center {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
