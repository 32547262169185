<template>
  <a :href="to">
    <div class="card">
      <img :src="image" alt="service"/>

      <div class="overlay">
        <h3>{{ title }}</h3>
        <p class="" v-html="text"></p>
        <div class="edge"></div>
      </div>
    </div>
  </a>
</template>
<script>
export default {
  props: {
    image: String,
    title: String,
    text: String,
    to: String,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

$height: 300px;
$title_height: 60px;

a {
  text-decoration: none;
  margin-bottom: 40px;
  .card {
    position: relative;
    height: $height;
    overflow: hidden;
    
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &:hover .overlay {
      height: 100%;
    }

    .overlay {
      position: absolute;
      display: inline-block;
      padding: 20px;
      z-index: 100;
      background-color: rgba(0, 0, 0, 0.6);
      width: 100%;
      box-sizing: border-box;
      height: $title_height;
      bottom: 0;
      transition: height .25s ease;
    }

    h3 {
      color: white;
      font-size: 20px;
      white-space: nowrap;
    }
    p {
      max-height: $height - $title_height;
      overflow: hidden;
      margin-bottom: 0;
      position: relative;
      color: #ccc;
      font-size: 14px;
    }
  }
}
</style>
