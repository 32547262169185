import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import BauberatungView from "../views/leistungen/Bauberatung.vue";
import BeweissicherungView from "../views/leistungen/Beweissicherung.vue";
import Gutachten from "../views/leistungen/Gutachten.vue";
import Objektplanung from "@/views/leistungen/Objektplanung";
import TechnischeDueDiligence from "@/views/leistungen/TechnischeDueDiligence";
import Sichtbeton from "@/views/leistungen/Sichtbeton";
import Dachraumausbau from "@/views/leistungen/Dachraumausbau";

import Fachartikel from "@/views/lehre_und_publikationen/Fachartikel";
import Fachbuecher from "@/views/lehre_und_publikationen/Fachbuecher";
import Seminare from "@/views/lehre_und_publikationen/Seminare";
import DasGrundeigentum from "@/views/lehre_und_publikationen/DasGrundeigentum";

import Stellen from "@/views/unternehmen/Stellen";
import Mitarbeiter from "@/views/unternehmen/Mitarbeiter";
import UeberUns from "@/views/unternehmen/Ueber_uns";

import Referenzen from "@/views/Referenzen";

import Impressum from "@/views/Impressum";
import Datenschutz from "@/views/Datenschutz";


Vue.use(VueRouter);

let titleBase = "IGS |";

const routes = [
  {
    path: "/",
    component: Index,
    meta: {
      title: `${titleBase} Bausachverständige in Berlin, Potsdam, Ibiza & Mallorca`,
      metaTags: [
        {
          name: "keywords",
          content: `
            Bausachverständige, Berlin, Potsdam, Gutachten, Sachverständige, Ibiza, Mallorca, Due Diligence, vereidigte Sachverständige,
            Beweissicherung, Bauberatung, was ist ein bausachverständiger, Bauschäden, Baubegleitung, Architekten,Ingenieure
           `,
        },
        {
          name: "description",
          content:
            "IGS Bausachverständige Berlin Potsdam Ibiza/Mallorca. Wir stehen für Erfahrung und Kompetenz in allen Fragen des Baugeschehens.",
        },
      ],
    },
  },
  {
    path: "/leistungen/bauberatung-baubegleitung",
    component: BauberatungView,
    meta: {
      title: `${titleBase} Bauberatung & Baubegleitung`,
      metaTags: [
        {
          name: "keywords",
          content: `
        Qualitätsüberwachung, Bauberatung, Baubegleitung, Sachverständige, Baustelle, Bauleitung, Qualitätskontrolle, Vertragskontrolle, 
        Baukosten, Gutachten, Mediator, Baupfusch, Öffentlich bestellte und vereidigte, öbuv
         `,
        },
        {
          name: "description",
          content: "",
        },
      ],
    },
  },
  {
    path: "/leistungen/beweissicherung",
    component: BeweissicherungView,
    meta: {
      title: `${titleBase} Beweissicherung & Tatsachenfeststellung`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Beweissicherung, Tatsachenfeststellung, Objektbegehung, Beweise, Bausubstanz, Schiedsgutachten, Gericht, Dokumentation
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/leistungen/sachverstaendigen-gutachten",
    component: Gutachten,
    meta: {
      title: `${titleBase} Sachverständigen Gutachten`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Baugutachten, Baumängel, Bauschäden, Schulz, Herzig, Gutachten, Sachverständige, Pflichten, öffentlich bestellt, IHK, Gericht, Sicherheit, 
           Bestandsaufnahme, Sichtung von Unterlagen, Fassaden, Schimmelpilz, Schiedsgutachten, Bodenbeläge, Baustoffe, Beton, Plattenbau,
           Legionellen, Grundwasser, Balkoninstandsetzung, Baudenkmal, Denkmalschutz, Hochbau, Schwimmbäder, Full Service, Sicherheitsarchitektur,
            Windkraftanlagen, Dachsanierung, Barrierefreiheit, Molkerei, Käserei, Rüttelbeläge, Generalplanung, Spritzbeton, Balkon, Bauschaden, Bericht,
            Asbest, Souterrain, Risse, Gipsputz, Tierfgarage, Parkhäuser, Sichtbeton, Möbel, Passivhäuser, Energieberatung, Thermographie, 
            Brandschutz, Bauteilanalysen, Langzeitmessungen, Beweissicherung, Versicherungsgutachten, Qualitätskontrolle, 
            Spanien, Ibiza, Mallorca, Rom, Algier, London, Mexiko City, Abdichtung
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/leistungen/sachverstaendigen-gutachten/:title",
    component: Gutachten,
    meta: {
      title: `${titleBase} `,
      metaTags: [
        {
          name: "keywords",
          content: `
           
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/leistungen/beratung-zur-objektplanung",
    component: Objektplanung,
    meta: {
      title: `${titleBase} Beratung zur Objektplanung`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Instandsetzungsplanung, Altbau Instandsetzung, Denkmalschutz, Dachgeschossausbau, WU-Beton, Betininstandsetzung
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/leistungen/technische-due-diligence",
    component: TechnischeDueDiligence,
    meta: {
      title: `${titleBase} Due Diligence`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Kaufentscheidung, Beratung, Immobilien, Prüfung des Zustands, Kostenschätzung, Modernisierung, Instandsetzung
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/leistungen/sichtbeton",
    component: Sichtbeton,
    meta: {
      title: `${titleBase} Sichtbeton`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Sichtbeton, Planung, Ausführung, Gutachter, Sichtbetonmängel, Sachverständiger, WU-Beton, Weiße-Wanne, Sanierung
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/leistungen/dachraumausbau",
    component: Dachraumausbau,
    meta: {
      title: `${titleBase} Dachraumausbau`,
      metaTags: [
        {
          name: "keywords",
          content: `
            Dachraumausbau, Dachgeschossausbau, Dachaufstockung, Ablaufschema, Bestandsaufnahme, Entwurfsplanung, Genehmigung, 
            Brandschutz, Wärmeschutz, Trittschallschutz, Dachterrassen, Tragwerksplanung, Statik
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },

  {
    path: "/lehre_und_publikationen/fachbuecher",
    alias:"/lehre-und-publikationen/fachbuecher",
    component: Fachbuecher,
    meta: {
      title: `${titleBase} Fachbücher von Joachim Schulz `,
      metaTags: [
        {
          name: "keywords",
          content: `
           Fachbücher, Joachim Schulz, Architektur der Bauschäden, Bauschäden, Nassräume, Sichtbeton, 
           Sichtbeton Mängel, Sichtbeton Handbuch, Mängelvermeidung, Schadensursachen
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/lehre_und_publikationen/fachartikel",
    component: Fachartikel,
    meta: {
      title: `${titleBase} Fachartikel von Joachim Schulz`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Joachim Schulz, Fachartikel, Sichtbeton, Betonkosmetik, Der Bausachverständige, Bauhandwerk
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/lehre_und_publikationen/seminare",
    component: Seminare,
    meta: {
      title: `${titleBase} Seminare`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Fotbildungen , Schulungen, Tagesseminare, Bauseminare, Schimmelpilz, Bäder, Nassräume,
            Bauwerksabdichtung, Abdichtung, Innenausbau, Planungsfehler, Bauüberwachung, Ausführungsfehler, 
            optische Fehler, Balkon, Terrasse, Fassade, Tiefgarage, Parkhaus, Minderwertberechnung, Sichtbetin, Außentreppen
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/lehre_und_publikationen/das-grundeigentum",
    component: DasGrundeigentum,
    meta: {
      title: `${titleBase} Das Grundeigentum`,
      metaTags: [
        {
          name: "keywords",
          content: `
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/unternehmen/ueber_uns",
    component: UeberUns,
    meta: {
      title: `${titleBase} über die IGS `,
      metaTags: [
        {
          name: "keywords",
          content: `
           IGS Schulz, Berlin, Potsdam, ö.b.u.V, Sachverständige, Architekten, Ingenieure, 1981, Berufserfahrung
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/unternehmen/mitarbeiter",
    component: Mitarbeiter,
    meta: {
      title: `${titleBase} Mitarbeiter`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Leitende Mitarbeiter, Joachim Schulz, Matthias Herzig, Architekten, Ingenieure, Sachverständige, Beton-Schäden, Instandsetzung, Sichtbetin, Hochschulabschluss, Fachbücher, Author, IHK
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/unternehmen/stellen",
    component: Stellen,
    meta: {
      title: `${titleBase} Stellenangebote`,
      metaTags: [
        {
          name: "keywords",
          content: `
            Ingenierbüro, Bewerbung, Studentischer Mitarbeiter, Teilzeit, Vollzeit, Sachverständigenwesen, Baudokumentation, Profil, Aufgaben,
            Organisationstalent , Flexible Arbeitszeiten, Weiterbildungen, Homeoffice
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/referenzen",
    component: Referenzen,
    meta: {
      title: `${titleBase} Referenzen`,
      metaTags: [
        {
          name: "keywords",
          content: `
           Gutachten, Referenzen, Planung, Berlin, ADAC, ARD, Handwerkszentrum, Finanzamt, B&B Berlin-Airport, Technopark, Hotel de Rome, Büro,
           Hotel, Denkmalschutz, Industrie, Industriehalle, Stadtvillen, Parkhaus, Seniorenresidenz, Seniorenwohnen, Deutsche Botschaft, 
           Bundesrat, Hasso-Plattner Institut, Grundschule, Landesvertretung, Rathaus, Uni Dessau, Flughafen BER, Robert Koch Institut, Forschung, 
           Therme, Kläranlage, Milchwerk, Feuerwache, Einkaufszentrum, Mailand, Staatskanzlei, Olympiastadion, 
           `,
        },
        { name: "description", content: "" },
      ],
    },
  },
  {
    path: "/impressum",
    component: Impressum,
    meta: {
      title: `${titleBase} Impressum`,
    },
  },
  {
    path: "/datenschutz",
    component: Datenschutz,
    meta: {
      title: `${titleBase} Datenschutz`,
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
