<template>
  <div>
    <section>
      <div class="container">
        <h1 :data-sub="$t('index.article_books.subline')">
          {{ $t("index.article_books.headline") }}
        </h1>

        <div class="row">
          <div class="col-lg-4 mb-50" v-for="(article, i) in articles" :key="i">
              <Card
                :title="article._title"
                :text="article._source + ' ' + article.source_date"
                :image="article._image"
                :icon_text="article.author"
                :image_height="500"
              />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Card from "@/components/Card.vue";

export default {
  components: {
    Card,
  },
  beforeMount() {
    this.articles = this.$t("articles");
  },
  data() {
    return {
      articles: [],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
