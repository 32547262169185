<template>
  <div class="carousel">
    <div
      class="slide"
      v-for="(slide, i) in slides"
      :key="i"
      :style="{ backgroundImage: 'url(' + slide + ')' }"
      :class="getSlideClass(i)"
    ></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentSlide: 0,
      slides: [
        "/assets/images/carousel/wide_1.jpg",
        "/assets/images/carousel/green_urban.jpg",
        "/assets/images/carousel/wide_2.jpg",
        "/assets/images/carousel/wide_3.jpg",
        "/assets/images/carousel/wide_4.jpg",
      ],
    };
  },
  mounted() {
    setInterval(() => {
      if (this.currentSlide + 1 < this.slides.length) {
        ++this.currentSlide;
      } else {
        this.currentSlide = 0;
      }
    }, 10000);
  },
  beforeUnmount() {},
  methods: {
    getSlideClass(index) {
      if (index == this.currentSlide) {
        return "active";
      }
      if (index == this.currentSlide - 1) {
        return "fading";
      } else if (this.currentSlide == 0 && index == this.slides.length - 1) {
        return "fading";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel {
  display: inline-block;
  height: 70vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  .slide {
    display: inline-flex;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: left 1s ease;
    justify-content: center;

    background-size: cover;
    background-position: center;

    left: 100%;
    z-index: -1;
    &.active {
      left: 0;
      z-index: 1;
    }
    &.fading {
      left: -100%;
      z-index: 1;
    }

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
