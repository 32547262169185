<template>
    <div>
        <section>
            <div class="container">
                <h1>{{ $t('references.headline') }}</h1>
                <div class="row noreduce">
                    <div class="col-lg-4" v-for="(ref, i) in $t('references.references')" :key="i">
                        <Card :title="ref.title" :text="ref.services" :icon_text="ref.category" :image="ref._image"/>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Card from "@/components/Card";
export default {
    components: {
        Card
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

</style>