<template>
  <div>
    <section>
      <div class="container">
        <h1 :data-sub="$t('index.books.subline')">
          {{ $t("index.books.headline") }}
        </h1>

        <div class="row">
          <div class="col-lg-4 mb-50" v-for="(book, i) in books" :key="i">
            <a :href="book._link">
              <Card
                :title="book._title"
                :text="book.desc + '<br><br> ISBN: ' + book.isbn"
                :image="book._image"
                :icon_text="book.author"
                :image_height="500"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Card from "@/components/Card.vue";

export default {
  components: {
    Card,
  },
  beforeMount() {
    this.books = this.$t("books");
  },
  data() {
    return {
      books: [],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
