<template>
  <div id="textimage">
    <div class="container">
      <div class="text">
        <div class="content">
          <h1 class="left" :data-sub="subline">
            {{ headline }}
          </h1>
          <p v-html="text"></p>
          <p>Unsere Erfahrungen entnehmen Sie bitte <a href="/lehre_und_publikationen/fachbuecher?lang=de">hier</a></p>
          <b class="contact">
            <span>{{ $t("global.contact_us") }}:</span>
            <span
              ><i class="fa fa-phone"></i>
              <a href="tel:+49303009830">+49 (30) / 300 98 30</a></span
            >
            <span
              ><i class="fa fa-envelope"></i>
              <a href="mailto:info@igs-schulz.de">info@igs-schulz.de</a>
            </span>
          </b>
        </div>
      </div>
      <div class="image">
        <img class="bg" :src="image" alt="background"/>
        <img class="fg" :src="image" alt="background"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    headline: String,
    subline: String,
    text: String,
    image: String,
  },
};
</script>
<style lang="scss" scoped>
#textimage {
  display: inline-flex;
  position: relative;
  flex-grow: 2;
  width: 100%;
  background-color: white;
  padding-bottom: 0;

  $text_width: 70%;
  $image_height: 60%;
  $image_width: 80%;

  .text,
  .image {
    display: inline-block;
    height: 100%;
  }
  .text {
    display: inline-flex;
    height: 100%;
    width: 100%;

    .content {
      min-height: $image_height;
      max-width: 60%;
      padding-right: 50px;
      margin: auto 0;
      width: 80%;
    }

    .contact {
      display: inline-block;
      width: 100%;
      margin-top: 50px;
      text-align: left;
      span {
        margin-right: 50px;

        i {
          margin-right: 5px;
        }
      }
    }
  }
  .image {
    width: 100% - $text_width;
    position: absolute;
    right: 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
    }
    img.bg {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    img.fg {
      z-index: 100;
      height: $image_height;
      position: absolute;
      left: -$image_width / 2;
      top: (100% - $image_height) / 2;
      width: $image_width;
      object-fit: cover;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
}

@media (max-width: 768px) {
  #textimage .text .contact {
    display: flex;
    flex-direction: column;
    span {
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 992px) {
  #textimage .container {
    display: flex;
    flex-direction: column;
    > div {
      position: relative;
      width: 100%;
      .content {
        max-width: 100%;
        width: 100%;
        padding: 50px 0;
        z-index: 100;
      }
      &.image {
        //display: none;
        margin-top: 20%;
        margin-bottom: 50px;
        .fg {
          width: 80%;
          height: 80%;
          top: -20%;
          left: 10%;
        }
        //##
        margin-top: 0;
        position: absolute;
        &:before {
          background-color: rgba(255, 255, 255, 0.8);
        }
        .fg {
          display: none;
        }
      }
    }
  }
}
</style>
