<template>
  <div id="index">
    <Carousel />

    <section id="welcome">
      <div class="container">
        <div class="row noreduce">
          <div class="col-lg-12">
            <div>
              <h1 class="center" :data-sub="$t('index.subline')">
                {{ $t("index.headline") }}
              </h1>
              <p class="block" v-html="$t('index.text')"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="bubbleCards">
      <div class="container">
        <div class="row noreduce">
          <div class="col-lg-4" v-for="(card, i) in cards" :key="i">
            <Card
              :title="card.title"
              :icon="card.props.icon"
              :text="card.text"
              :image="card.props.image"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="highlighted" id="leistungen">
      <div class="container">
        <h2>{{ $t("index.leistungen.headline") }}</h2>
        <div class="row noreduce">
          <div
            class="col-lg-6"
            v-for="(service, i) in services"
            :key="'s_' + i"
          >
            <div class="checkmark">
              <i class="fa fa-check"></i>
            </div>
            <div class="content">
              <h3>{{ service.title }}</h3>
              <p>
                {{ service.text }}
                <a :href="service.props.to">{{ $t("global.read_more") }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <h2>{{ $t("global.most_searched") }}</h2>
        <div class="row noreduce most-searched">
          <div class="col-lg-3" v-for="(item, i) in searched" :key="i">
            <div class="card">
              <a :href="item.link">
                <img :src="item.image" alt="search-preview" />
                <b>{{ item.title }}</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="highlighted" id="ausland">
      <div class="container">
        <h2 :data-sub="$t('index.ausland.subline')">
          {{ $t("index.ausland.headline") }}
        </h2>
        <div class="row noreduce">
          <div class="col-lg-6">
            <img
              src="/assets/images/stock/ausland_2.jpg"
              height="350"
              alt="foreign country"
            />
          </div>
          <div class="col-lg-6">
            <p v-html="$t('index.ausland.text')"></p>
            <p class="tags">
              <span
                v-for="(tag, index) in $t('index.ausland.links')"
                :key="tag.title"
                ><a :href="tag._to">{{ tag.title }}</a
                ><i v-if="index < $t('index.ausland.links').length - 1">&bull;</i></span
              >
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="books">
      <div class="container">
        <h2 :data-sub="$t('index.books.subline')">
          {{ $t("index.books.headline") }}
        </h2>

        <div class="row noreduce">
          <div class="col-lg-12 books">
            <p class="center">
              {{ $t("index.books.more_cta") }}
              <a href="/lehre_und_publikationen/fachbuecher">{{
                $t("global.here")
              }}</a>
            </p>
          </div>
          <div class="col-lg-4" v-for="(book, i) in books" :key="i">
            <Card
              :title="book._title"
              :text="book.desc"
              :image="book._image"
              :icon_text="book.author"
              :image_height="500"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Carousel from "@/components/Carousel";
import Card from "@/components/Card";

export default {
  components: {
    Carousel,
    Card,
  },
  beforeMount() {
    this.cards = this.$t("index.question_cards");
    this.cards[0].props = {
      icon: "fa fa-helmet-safety",
      image: "/assets/images/stock/worker.jpg",
    };
    this.cards[1].props = {
      icon: "fa fa-clock",
      image: "/assets/images/stock/desk.jpg",
    };
    this.cards[2].props = {
      icon: "fa fa-ruler-combined",
      image: "/assets/images/stock/measuring.jpg",
    };

    this.services = this.$t("index.leistungen.list");
    this.services[0].props = { to: "/leistungen/technische-due-diligence" };
    this.services[1].props = { to: "/leistungen/bauberatung-baubegleitung" };
    this.services[2].props = { to: "/leistungen/beratung-zur-objektplanung" };
    this.services[3].props = { to: "/leistungen/beweissicherung" };
    this.services[4].props = { to: "/leistungen/sichtbeton" };
    this.services[5].props = { to: "/leistungen/dachraumausbau" };
    this.services[6].props = { to: "/leistungen/sachverstaendigen-gutachten" };
    this.services[7].props = { to: "#ausland" };

    this.books[0].text = this.$t("index.books.descriptions.0.desc");
    this.books[1].text = this.$t("index.books.descriptions.1.desc");
    this.books[2].text = this.$t("index.books.descriptions.2.desc");

    this.books = this.$t("books").slice(0, 3);

    this.searched = [
      {
        title: this.$t("techdd").headline,
        link: "/leistungen/technische-due-diligence",
        image: "/assets/images/techdd.jpeg",
      },
      {
        title: this.$t("gutachten.gutachten").filter((ga) => ga.id == 9)[0]
          .title,
        link: "/leistungen/sachverstaendigen-gutachten/balkongutachten",
        image: "/assets/images/evaluations/balkon_gutachten.jpg",
      },
      {
        title: this.$t("bauberatung").headline,
        link: "/leistungen/bauberatung-baubegleitung",
        image: "/assets/images/grundriss.jpg",
      },
      {
        title: this.$t("gutachten").headline,
        link: "/leistungen/sachverstaendigen-gutachten",
        image: "/assets/images/evaluations/stock/versicherungsgutachten.jpg",
      },
    ];
  },
  data() {
    return {
      books: [
        {
          title: "Architektur der Bauschäden",
          image: "/assets/images/books/adb.jpeg",
          icon_text: "von Joachim Schulz",
        },
        {
          title: "Bauschäden in Bädern und Nassräumen",
          image: "/assets/images/books/Bauschaeden.jpeg",
          icon_text: "von Joachim Schulz",
        },
        {
          title: "Sichtbeton 4. Auflage",
          image: "/assets/images/books/sichtbeton_cover.png",
          icon_text: "von Joachim Schulz",
        },
      ],
      searched: [],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.tags {
  i {
    margin: 0 5px;
  }
}

#leistungen {
  .checkmark {
    margin-right: 40px;
    font-size: 2rem;
    color: $color_success;
  }
  [class^="col-"] {
    flex-direction: row;
    margin-bottom: 50px;
    &:nth-child(odd) {
      padding-right: 40px;
    }
    &:nth-child(even) {
      padding-left: 40px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    height: 100%;
    a {
      display: inline-block;
      color: $color_accent;
    }
  }
}

.most-searched {
  .card {
    background-color: $color_bold;
    box-shadow: $shadow;
    border: none;
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
    b {
      display: inline-block;
      margin: 15px 0;
      color: white;
    }
  }
}

.books {
  a {
    width: 100%;
    margin-top: 40px;
  }
}
</style>
