<template>
  <div>
    <section>
      <div class="container">
        <h1 :data-sub="$t('dasGrundeigentum.subline')">
          {{ $t("dasGrundeigentum.headline") }}
        </h1>
        <div class="row" style="text-align: left">
          <div class="col-4">
            1. Balkonfußboden-Sanierung/Altbau (GE 2002 [22] 1483 ff.)
          </div>
          <div class="col-4">
            2. Terrassen-Türschwelle (GE 2002 [23] 1554 f.)
          </div>
          <div class="col-4">
            3. Terrassen-Fußbodenbelag (GE 2003 [1] 32 f.)
          </div>
          <div class="col-4">
            4. Balkon-, Terrassen-Geländer (GE 2003 [2] 111)
          </div>
          <div class="col-4">5. Terrassen-Außentreppen (GE 2003 [3] 177)</div>
          <div class="col-4">6. Hauseingangs-Türschwelle (GE 2003 [4] 249)</div>
          <div class="col-4">7. Keller-Außentreppe (GE 2003 [5] 315 f.)</div>
          <div class="col-4">8. Kellerabdichtung (GE 2003 [6] 382 f.)</div>
          <div class="col-4">
            9. Bade- und Duschwanneneinbau (GE 2003 [7] 450 f.)
          </div>
          <div class="col-4">10. Bad-Abdichtung (GE 2003 [8] 517)</div>
          <div class="col-4">11. Fenstermontage (GE 2003 [9] 577)</div>
          <div class="col-4">
            12. Dachraumausbau - Wärmedämmung (GE 2003 [10] 661)
          </div>
          <div class="col-4">
            13. Dachraumausbau - Dachgauben (GE 22003 [11] 727)
          </div>
          <div class="col-4">
            14. Niveaugleiche Türschwellen (GE 2003 [12] 801)
          </div>
          <div class="col-4">15. Geländer, Statik? (GE 2003 [13] 869)</div>
          <div class="col-4">16. Sichtmauerwerk (GE 2003 [14] 938)</div>
          <div class="col-4">17. KG: WU-Beton (GE 2003 [15] 1008)</div>
          <div class="col-4">
            18. Souterrain: nachträglicher Ausbau (GE 2003 [16] 1075)
          </div>
          <div class="col-4">19. Putzschäden (GE 2003 [17] 1143)</div>
          <div class="col-4">20. Schimmel, Lüftung? (GE 2003 [18] 1202)</div>
          <div class="col-4">
            21. Geplante Instandhaltung (GE 2003 [19] 1265)
          </div>
          <div class="col-4">
            22. Steigendes Grundwasser (GE 2003 [20] 1322)
          </div>
          <div class="col-4">
            23. Sachverständige Privat/Gericht (GE 2003 [21] 1418)
          </div>
          <div class="col-4">
            24. Sichtflächen - Burteilung (z. B. Fassadenflächen) (GE 2003 [22]
            1485)
          </div>
          <div class="col-4">25. Abdichtung im Gefälle (GE 2003 [23] 1546)</div>
          <div class="col-4">
            26. Balkonabläufe/Fußbodenentwässerung (GE 2003 [24] 1604)
          </div>
          <div class="col-4">27. Balkon-Fallrohre (GE 2004 [1] 44)</div>
          <div class="col-4">28. Balkon-Notüberläufe (GE 2004 [2] 101)</div>
          <div class="col-4">
            29. Balkon mit offener Brüstung (GE 2004 [3] 168)
          </div>
          <div class="col-4">30. Innenliegende Bäder (GE 2004 [4] 226)</div>
          <div class="col-4">31. Innere Fensterbank (GE 2004 [5] 291)</div>
          <div class="col-4">32. Fassaden-Verschmutzung (GE 2004 [6] 347)</div>
          <div class="col-4">
            33. Äußere Abdeckungen/Tropfkanten (GE 2004 [7] 415)
          </div>
          <div class="col-4">
            34. Balkonbrüstungen (Mauerwerk) (GE 2004 [8] 475)
          </div>
          <div class="col-4">35. Mauerwerksabdeckungen (GE 2004 [9] 534)</div>
          <div class="col-4">
            36. Äußere Fensterbank aus Naturstein (GE 2004 [9] 534)
          </div>
          <div class="col-4">
            37. Äußere Fensterbank aus Alu (GE 2004 [10] 608)
          </div>
          <div class="col-4">
            38. Äußere Fensterbank aus Zink (GE 2004 [12] 740)
          </div>
          <div class="col-4">39. Außenanlage (GE 2004 [13] 807)</div>
          <div class="col-4">40. Kellerlichtschächte (GE 2004 [14] 874)</div>
          <div class="col-4">41. Elastische Verfugungen (GE 2004 [15] 941)</div>
          <div class="col-4">
            42. Algenbildung auf der WDVS-Fassade (GE 2004 [16] 1016)
          </div>
          <div class="col-4">
            43. Fugenverspachtelung auf Trockenbauflächen (GE 2004 [17] 1082)
          </div>
          <div class="col-4">
            44. Balkonbrüstungen/Betonfertigteile (GE 2004 [18] 1161)
          </div>
          <div class="col-4">45. Sichtbeton Planung (GE 2004 [19] 1222)</div>
          <div class="col-4">
            46. Spachtelarbeiten am Beton (GE 2004 [20] 1286)
          </div>
          <div class="col-4">47. Beton-Instandsetzung (GE 2004 [20] 1286)</div>
          <div class="col-4">
            48. Tropfkanten an auskragenden Stahlbeton-Balkondecken (GE 2004
            [23] 1516)
          </div>
          <div class="col-4">
            49. Balkon - Fußbodenbeschichtungen (GE 2005 [1] 45)
          </div>
          <div class="col-4">
            50. Balkon - „Alternative“ Abdichtung (GE 2005 [2] 118)
          </div>
          <div class="col-4">
            51. Fliesen-Fußboden/Gefälle (GE 2005 [3] 168)
          </div>
          <div class="col-4">
            52. Grundwasser: Nachträgliche Wannenausbildung (GE 2005 [4] 226)
          </div>
          <div class="col-4">53. Terrassen: Bodenbeläge (GE 2005 [5] 289)</div>
          <div class="col-4">
            54. Fassadensockel-Vorsprung (GE 2005 [11] 661)
          </div>
          <div class="col-4">55. Sockel - Spritzwasser (GE 2005 [12] 724)</div>
          <div class="col-4">
            56. Badezimmer - Holzspanplatten (GE 2005 [13] 788)
          </div>
          <div class="col-4">57. Tiefgaragen - Fußböden (GE 2005 [14] 851)</div>
          <div class="col-4">
            58. Tiefgaragen - Rampenwände (GE 2005 [15] 911)
          </div>
          <div class="col-4">
            59. Tiefgaragen - Rampengeländer (GE 2005 [16] 975)
          </div>
          <div class="col-4">
            60. Tiefgaragen - Entwässerung (GE 2005 [17] 1048)
          </div>
          <div class="col-4">
            61. Freitreppen - Korrosion (GE 2005 [18] 1115)
          </div>
          <div class="col-4">
            62. Glasfassade - Heizkörper (GE 2005 [19] 1179)
          </div>
          <div class="col-4">
            63. Sonnenschutz - Schwerter (GE 2005 [20] 1240)
          </div>
          <div class="col-4">
            64. Fassade - Fliesenbelag (GE 2005 [21] 1343)
          </div>
          <div class="col-4">
            65. Balkone mit Fliesenbelag (GE 2005 [22] 1412)
          </div>
          <div class="col-4">
            66. Qualitätskontrollen am Bau (GE 2005 [23] 1480)
          </div>
          <div class="col-4">
            67. Aufenthaltsräume im Keller aus WU-Beton (GE 2005 [24] 1542)
          </div>
          <div class="col-4">
            68. Terrasse: Bodenbelag aus Holzbohlen (GE 2006 [1] 44)
          </div>
          <div class="col-4">
            69. Tropfkanten/äußere Fensterbänke (GE 2006 [6] 370)
          </div>
          <div class="col-4">70. Fassadeninstandsetzung (GE 2006 [7] 436)</div>
          <div class="col-4">
            71. Bauunterhalt „geplante Instandhaltung“ (GE 2006 [8] 498)
          </div>
          <div class="col-4">
            72. Sichtbetonflächen sind Betonflächen mit Anforderungen an das
            Aussehen (GE 2006 [9] 564)
          </div>
          <div class="col-4">73. Maßtoleranzen (GE 2006 [11] 704)</div>
          <div class="col-4">74. Souterrain-Terrasse (GE 2006 [12] 772)</div>
          <div class="col-4">75. Hochparterre-Terrasse (GE 2006 [14] 901)</div>
          <div class="col-4">76. Balkon-Rinnen (GE 2006 [16] 1028)</div>
          <div class="col-4">
            77. Fassaden-Verschmutzung/Dachtraufe (GE 2006 [17] 1091)
          </div>
          <div class="col-4">78. Erker/Fassade (GE 2006 [18] 1152)</div>
          <div class="col-4">79. Kellerfenster (GE 2006 [21] 1396)</div>
          <div class="col-4">80. Balkonbrüstungen (GE 2006 [22] 1462)</div>
          <div class="col-4">
            81. Balkonfußbodensanierung beim Altbau (GE 2006 [23] 1539)
          </div>
          <div class="col-4">
            82. Feuchtigkeitsschäden durch Diffusion (GE 2006 [24] 1603)
          </div>
          <div class="col-4">83. Terrasse im Erdgeschoß (GE 2007 [1] 42)</div>
          <div class="col-4">
            84. Balkone - nach zehn Jahren Komplettsanierung? (GE 2007 [2] 140)
          </div>
          <div class="col-4">
            85. Terrassenbrüstung/Holzverkleidung (GE 2007 [3] 211)
          </div>
          <div class="col-4">86. Außentreppen (GE 2007 [5] 356)</div>
          <div class="col-4">
            87. Unterspannbahn – Durchdringungen (GE 2007 [6] 437)
          </div>
          <div class="col-4">
            88. Feuchtigkeitsschaden im Treppenhaus (GE 2007 [7] 507)
          </div>
          <div class="col-4">
            89. Kelleraußentreppe: Sanierung der Sanierung (GE 2007 [9] 644)
          </div>
          <div class="col-4">
            90. Einfamilienhaus – nicht unterkellert (GE 2007 [10] 705)
          </div>
          <div class="col-4">91. Kelleraußentreppen (GE 2007 [11] 773)</div>
          <div class="col-4">92. Kelleraußentreppen (GE 2007 [12] 837)</div>
          <div class="col-4">
            93. Nachträglicher Anbau einer Tiefgarage (GE 2007 [13] 897)
          </div>
          <div class="col-4">
            94. WBS 70 Plattenbau-Sanierung (GE 2007 [14] 980)
          </div>
          <div class="col-4">
            95. Balkon – Gefälle zum Gebäude? (GE 2007 [15] 1044)
          </div>
          <div class="col-4">96. Brandschutz (GE 2007 [16] 1105)</div>
          <div class="col-4">
            97. Dachsanierung (Wellasbestplatten) (GE 2007 [17] 1177)
          </div>
          <div class="col-4">98. Flachdachsanierung (GE 2007 [18] 1239)</div>
          <div class="col-4">
            99. Tiefgaragen - Luftschächte (GE 2007 [19] 1308)
          </div>
          <div class="col-4">100. Hauseingangspodest (GE 2007 [20] 1371)</div>
          <div class="col-4">
            101. Dachraumausbau „Tropfsteinhöhle“ (GE 2007 [21] 1476)
          </div>
          <div class="col-4">
            102. Dachraumausbau „Dachausstieg“ (GE 2007 [23] 1616)
          </div>
          <div class="col-4">
            103. Schimmelpilz „Wärmebrücken“ (GE 2008 [1] 42)
          </div>
          <div class="col-4">104. Instandhaltungsplanung (GE 2008 [3] 178)</div>
          <div class="col-4">
            105. Altbau/Balkonfußbodensanierung (GE 2008 [4] 255)
          </div>
          <div class="col-4">106. Loggien (GE 2008 [5] 322)</div>
          <div class="col-4">107. EG-Terrassen (GE 2008 [6] 393)</div>
          <div class="col-4">
            108. Kellerlichtschächte (Neubau) (GE 2008 [7] 466)
          </div>
          <div class="col-4">
            109. Hoftüren und Kellerabgänge (GE 2008 [8] 530)
          </div>
          <div class="col-4">
            110. Kelleraußentreppe (Altbau) (GE 2008 [9] 595)
          </div>
          <div class="col-4">
            111. Schornstein und Feuchtigkeit (GE 2008 [10] 656)
          </div>
          <div class="col-4">112. Garagen-Anbau (GE 2008 [11] 722)</div>
          <div class="col-4">
            113. Gefälle zum Gebäude – Feuchtigkeitsschäden (GE 2008 [12] 786)
          </div>
          <div class="col-4">
            114. Duschanlagen – Undichtigkeiten (GE 2008 [13] 850)
          </div>
          <div class="col-4">
            115. Flachdachsanierung – Foliendach (GE 2008 [14] 913)
          </div>
          <div class="col-4">
            116. Balkonsanierung – neue Balkontüranlage (GE 2008 [15] 974)
          </div>
          <div class="col-4">
            117. Sichtbeton oder begrünte Fassade? (GE 2008 [16] 1042)
          </div>
          <div class="col-4">
            118. Mauerwerksfeuchte im Erdgeschoss (GE 2008 [17] 1110)
          </div>
          <div class="col-4">
            119. Sanierung der Sanierung, Teil 1: Balkone (GE 2008 [18] 1186)
          </div>
          <div class="col-4">120. Fenster: Folgekosten (GE 2008 [19] 1248)</div>
          <div class="col-4">121. Terrassensanierung (GE 2008 [20] 1314)</div>
          <div class="col-4">
            122. Sanierung der Sanierung, Teil 2: Terrassen (GE 2008 [21] 1415)
          </div>
          <div class="col-4">
            123. Terrassenbeläge: Holzbohlen (GE 2008 [23] 1546)
          </div>
          <div class="col-4">
            124. Abdichtung auf WDVS-Fassade (GE 2008 [24] 1614)
          </div>
          <div class="col-4">
            125. Niveaugleiche Türschwellen: Terrasse (GE 2009 [1] 41)
          </div>
          <div class="col-4">
            126. Kellerdecke – Einsturzgefahr (GE 2009 [2] 106)
          </div>
          <div class="col-4">
            127. Dachterrasse – fehlende Planung (GE 2009 [4] 251)
          </div>
          <div class="col-4">
            128. Kelleraußentreppe bei Lehmboden (GE 2009 [5] 314)
          </div>
          <div class="col-4">
            129. Niveaugleiche Türschwellen: Hauseingänge (GE 2009 [6] 379)
          </div>
          <div class="col-4">130. Balkonverglasung (GE 2009 [7] 443)</div>
          <div class="col-4">
            131. Keller - Undichtigkeiten (GE 2009 [9] 568)
          </div>
          <div class="col-4">
            132. Keller bei Einfamilienhäusern, Teil 1 (GE 2009 [11] 705)
          </div>
          <div class="col-4">
            133. Keller bei Einfamilienhäusern, Teil 2 (GE 2009 [13] 834)
          </div>
          <div class="col-4">
            134. Stuckfassaden aus Gips (GE 2009 [15] 969)
          </div>
          <div class="col-4">
            135. Keller bei Einfamilienhäusern, Teil 3 (GE 2009 [20] 1306)
          </div>
          <div class="col-4">
            136. Balkon-Geländer, Teil 1 (GE 2009 [22] 1483)
          </div>
          <div class="col-4">
            137. Balkon-Geländer, Teil 2 (GE 2009 [23] 1545)
          </div>
          <div class="col-4">138. Balkon-Geländer, Teil 3 (GE 2010 [1] 49)</div>
          <div class="col-4">139. Souterrain-Terrassen (GE 2010 [2] 114)</div>
          <div class="col-4">
            140. Flachdach-Absturzsicherungen (GE 2010 [3] 188)
          </div>
          <div class="col-4">
            141. Geruchsbelästigung Terrasse (GE 2010 [4] 256)
          </div>
          <div class="col-4">142. Feuchte Altbaukeller (GE 2010 [5] 325)</div>
          <div class="col-4">143. Loggien – Abdichtung (GE 2010 [7] 472)</div>
          <div class="col-4">
            144. Eigenleistung Wanddurchbruch (GE 2010 [10] 682)
          </div>
          <div class="col-4">
            145. Souterrain: Eingangstür (GE 2010 [11] 752)
          </div>
          <div class="col-4">
            146. Balkon-Fußbodenbeschichtung (lAtbau) (GE 2010 [14] 965)
          </div>
          <div class="col-4">
            147. Innendämmung als Alternative? (GE 2010 [15] 1043)
          </div>
          <div class="col-4">
            148. Souterrain: Rückstauklappe (GE 2010 [16] 1102)
          </div>
          <div class="col-4">
            149. Sichtbeton Hydrophobierung (GE 2010 [17] 1186)
          </div>
          <div class="col-4">
            150. Balkon-Instandsetzung (GE 2010 [21] 1531)
          </div>
          <div class="col-4">
            151. Jetzt planen: Poolsanierung (GE 2010 [23] 1674)
          </div>
          <div class="col-4">
            152. Winter – Tauwasser: Gesims-Rinne, Teil 1 (GE 2011 [7] 474)
          </div>
          <div class="col-4">153. Bad – Rauhfasertapeten (GE 2011 [9] 604)</div>
          <div class="col-4">
            154. Winter – Tauwasserschäden, Teil 2 (GE 2011 [9] 605)
          </div>
          <div class="col-4">
            155. Innenliegendes Bad – Lüftung (GE 2011 [10] 671)
          </div>
          <div class="col-4">156. Bad – Fliesenfugen (GE 2011 [10] 672)</div>
          <div class="col-4">157. Badabdichtungen (GE 2011 [11] 747)</div>
          <div class="col-4">158. Hauseingangstreppe (GE 2011 [13] 873)</div>
          <div class="col-4">159. Risse im Sichtbeton (GE 2011 [14] 943)</div>
          <div class="col-4">
            160. „Frostschürze“/Fundamentsohle (GE 2011 [15] 1010)
          </div>
          <div class="col-4">
            161. Bodengleiche Duschen in Wohnungen, Teil 1 (GE 2011 [16] 1071)
          </div>
          <div class="col-4">
            162. Schimmelpilz – Wärmebrücken (GE 2011 [17] 1145)
          </div>
          <div class="col-4">
            163. Balkon-Türschwellen – Altbau (GE 2011 [18] 1216)
          </div>
          <div class="col-4">
            164. Dachraumausbau – Dampfsperre (GE 2011 [19] 1293)
          </div>
          <div class="col-4">
            165. Keller – Abdichtung mit KMB-“Dickbeschichtung“ (GE 2011 [20]
            1362)
          </div>
          <div class="col-4">
            166. Baubegleitende Qualitätsüberprüfung – BQÜ (GE 2011 [21] 1479)
          </div>
          <div class="col-4">
            167. Keller in Berlin - steigendes Grundwasser (GE 2011 [22] 1542)
          </div>
          <div class="col-4">
            168. Bauabnahme Haus/Wohnung (GE 2011 [23] 1610)
          </div>
          <div class="col-4">
            169. Balkon (Altbau) – neue Brüstung (GE 2011 [24] 1674)
          </div>
          <div class="col-4">
            170. Fassaden – Instandsetzung/Denkmalschutz (GE 2012 [1] 55)
          </div>
          <div class="col-4">
            171. Winter – Tauwasserschäden, Teil 3: Innenliegende Dachrinne (GE
            2012 [4] 258)
          </div>
          <div class="col-4">172. Fassaden - Bauabnahme (GE 2012 [5] 324)</div>
          <div class="col-4">
            173. Decken – Spachtelarbeiten (Filigran-Decken) (GE 2012 [5] 325)
          </div>
          <div class="col-4">
            174. Fassaden – Fenstersturz aus Stahlträger (GE 2012 [6] 399)
          </div>
          <div class="col-4">175. Beweissicherung (GE 2012 [7] 475)</div>
          <div class="col-4">
            176. Rollladen- bzw. Jalousienkasten (GE 2012 [8] 539)
          </div>
          <div class="col-4">177. Hofentwässerung (GE 2012 [9] 605)</div>
          <div class="col-4">
            178. Estricharbeiten – „schwimmender“ Estrich (GE 2012 [10] 678)
          </div>
          <div class="col-4">
            179. Fassadeninstandsetzung / Denkmalschutz (GE 2012 [11] 746)
          </div>
          <div class="col-4">
            180. Balkonabdichtung „Dichtschlämme“ (GE 2012 [12] 817)
          </div>
          <div class="col-4">
            181. Kellerdeckeninstandsetzung – „Stahlsteindecke“ (GE 2012 [12]
            818)
          </div>
          <div class="col-4">
            182. Revisionsklappen an Badewannenverkleidung nötig? (GE 2012 [13]
            885)
          </div>
          <div class="col-4">
            183. Drainagebahnen über Balkonabdichtung (GE 2012 [13] 886)
          </div>
          <div class="col-4">
            184. WDVS-Fassade – Problemzonen, Teil 1 (GE 2012 [14] 947)
          </div>
          <div class="col-4">
            185. Hinweispflicht auf Schimmelrisiko bei Altbauten (GE 2012 [15]
            1025)
          </div>
          <div class="col-4">
            186. Geländerhöhen richtig planen (GE 2012 [16] 1090)
          </div>
          <div class="col-4">
            187. Balkondecken: Stahl-/Holzkonstruktion (Teil 1) (GE 2012 [19]
            1305)
          </div>
          <div class="col-4">
            188. WDVS-Fassade, Kompriband (GE 2012 [21] 1483)
          </div>
          <div class="col-4">
            189. Bauabnahme bei Immobilienerwerb – Teil 1 (GE 2012 [24] 1689)
          </div>
          <div class="col-4">
            190. Flachdach im Gefälle, Pfützenbildung? (GE 2013 [1] 42)
          </div>
          <div class="col-4">
            191. Fensterbänke - Tropfkanten erforderlich? (GE 2013 [5] 341)
          </div>
          <div class="col-4">
            192. Bodengleiche Duschen in Wohnungen, Teil 2 (GE 2013 [6] 405)
          </div>
          <div class="col-4">
            193. Staffelgeschoss Außenwände, Dachüberstand (GE 2013 [7] 474)
          </div>
          <div class="col-4">
            194. Keller-Lichtschächte, Teil 3 (Neubau) (GE 2013 [9] 602)
          </div>
          <div class="col-4">
            195. Bauabnahme beim Immobilienerwerb, Teil 2 (GE 2013 [10] 669)
          </div>
          <div class="col-4">196. Typische Baufehler (GE 2013 [11] 737)</div>
          <div class="col-4">
            197. Geländer richtig planen, Teil 2 (GE 2013 [13] 868)
          </div>
          <div class="col-4">
            198. Keller, Außentreppe (Neubau) - Teil 1 (GE 2013 [15] 992)
          </div>
          <div class="col-4">
            199. Innenputz - Bauabnahme (GE 2013 [18] 1190)
          </div>
          <div class="col-4">200. Kelleraußentreppe(GE 2013 [21] 1445)</div>
          <div class="col-4">
            201. Sichtbeton - sich betonen in Form, Konstruktion und
            Originalität (GE 2013 [23] 1578)
          </div>
          <div class="col-4">
            202. Balkontüren - Trittbleche (GE 2014 [4] 242)
          </div>
          <div class="col-4">
            203. WDVS-Fassade – Problemzonen, Teil 2 (GE 2014 [5] 304)
          </div>
          <div class="col-4">
            204. Schlagregenbelastung - Terrassen (GE 2014 [7] 448)
          </div>
          <div class="col-4">205. Attika – Abdeckung (GE 2014 [8] 512)</div>
          <div class="col-4">206. Neubau einer Villa (GE 2014 [9] 581)</div>
          <div class="col-4">
            207. Randabsenkungen bei Fliesenbelägen auf schwimmendem Estrich (GE
            2014 [10] 654)
          </div>
          <div class="col-4">
            208. Sichtflächen-Bewertung (GE 2014 [11] 729)
          </div>
          <div class="col-4">
            209. Kellerfeuchte durch Reste der Nachbarbebauung (GE 2014 [14]
            924)
          </div>
          <div class="col-4">
            210. Bodengleiche Duschräume (GE 2014 [15] 989)
          </div>
          <div class="col-4">
            211. Gefälle oder Pfützen in Küchen (GE 2014 [16] 1049)
          </div>
          <div class="col-4">
            212. WDVS-Fassaden - Problemzonen, Teil 3, Gesimse (GE 2014 [17]
            1118)
          </div>
          <div class="col-4">
            213. Tiefgarage Rampe – Entwässerung, Teil 1 (GE 2014 [18] 1190)
          </div>
          <div class="col-4">
            214. WDVS-Fassade – Problemzonen, Teil 4, Vorsprünge und
            Durchdringungen (GE 2014 [19] 1262)
          </div>
          <div class="col-4">215. Außentreppen (GE 2014 [20] 1324)</div>
          <div class="col-4">
            216. Bauabnahme mit Hilfe von „Streiflicht“ (GE 2014 [22] 1519)
          </div>
          <div class="col-4">
            217. Holzfußbodenbeläge für Balkone und Terrassen (GE 2014 [23]
            1577)
          </div>
          <div class="col-4">218. Feuchte Altbaukeller (GE 2014 [24] 1639)</div>
          <div class="col-4">
            219. Badewanne vor dem Fenster (GE 2015 [1] 46)
          </div>
          <div class="col-4">
            220. Hausverwaltungen und kostenlose Firmenangebote (GE 2015 [2]
            110)
          </div>
          <div class="col-4">221. Schäden am Blechdach (GE 2015 [3] 173)</div>
          <div class="col-4">
            222. Tiefgarage Rampe – Entwässerung, Teil 2 (GE 2015 [4] 241)
          </div>
          <div class="col-4">
            223. Balkone – nachträglich vorgesetzt (GE 2015 [5] 308)
          </div>
          <div class="col-4">
            224. Gefälle oder Pfützen in Tiefgaragen (GE 2015 [6] 373)
          </div>
          <div class="col-4">
            225. Terrassenbrüstung – Abdeckung (GE 2015 [7] 440)
          </div>
          <div class="col-4">
            226. Auf der Suche nach wesentlichen Mängeln: Bodenbeläge (GE 2015
            [9] 581)
          </div>
          <div class="col-4">
            227. Schlagregendichtheit Kastenfenster (GE 2015 [10] 645)
          </div>
          <div class="col-4">228. Brüstungshöhen (GE 2015 [11] 704)</div>
          <div class="col-4">
            229. Auf der Suche nach wesentlichen Mängeln: Bauteiltoleranzen
            (Teil 1) (GE 2015 [12] 777)
          </div>
          <div class="col-4">
            230. Auf der Suche nach wesentlichen Mängeln: Sichtflächenbewertung
            (Teil 2) (GE 2015 [13] 845)
          </div>
          <div class="col-4">
            231. Fensterbänke aus Rollschichten (GE 2015 [15] 961)
          </div>
          <div class="col-4">
            232. Abdichtungsaufkantungshöhe an Schwellen (GE 2015 [16] 1018)
          </div>
          <div class="col-4">
            233. Feuchte Altbaukeller (Teil 4): Lichtschächte im Altbau -
            „Isoliergraben“ (GE 2015 [17] 1081)
          </div>
          <div class="col-4">234. Risse an der Fassade (GE 2015 [19] 1217)</div>
          <div class="col-4">
            235. Außentüren – Türschwellen-Abdichtung (GE 2015 [20] 1279)
          </div>
          <div class="col-4">
            236. Badewannen – Feuchtigkeitsschäden (GE 2015 [21] 1392)
          </div>
          <div class="col-4">
            237. Architekten – Ausführungsplanung (GE 2015 [22] 1453)
          </div>
          <div class="col-4">
            238. Außentüren, nach außen aufschlagend (GE 2015 [23] 1522)
          </div>
          <div class="col-4">
            239. Altbau-Kastendoppelfenster - winddicht? (GE 2016 [1] 44)
          </div>
          <div class="col-4">
            240. WDVS-Fassade – Problemzonen, Teil 5: Obere Fassadenabschlüsse
            (GE 2016 [3] 182)
          </div>
          <div class="col-4">
            241. WDVS-Fassade – Problemzonen, Teil 6: Untere Fassadenabschlüsse
            (GE 2016 [5] 312)
          </div>
          <div class="col-4">242. Innendämmung (GE 2016 [7] 447)</div>
          <div class="col-4">
            243. Bauabnahme bei Immobilienerwerb, Teil 3 (GE 2016 [8] 513)
          </div>
          <div class="col-4">
            244. Fassade: Fensterrahmen und -bänke aus Aluminium (GE 2016 [10]
            645)
          </div>
          <div class="col-4">245. Sichtbeton – Hinweise (GE 2016 [11] 711)</div>
          <div class="col-4">
            246. Badschäden [Teil 1]: Badezimmerabdichtung (allgemein) (GE 2016
            [21] 1371)
          </div>
          <div class="col-4">
            247. Sichtbeton als Fertigteile (GE 2016 [22] 1437)
          </div>
          <div class="col-4">
            248. Sichtbeton aus „WU-Beton“ (GE 2016 [24] 1560)
          </div>
          <div class="col-4">
            249. Badschäden (Teil 2): Abdichtung im Türbereich (GE 2017 [2] 93)
          </div>
          <div class="col-4">
            250. Sichtbeton mit „Betonkosmetik“ (GE 2017 [5] 277)
          </div>
          <div class="col-4">251. Möbel aus Sichtbeton (GE 2017 [7] 408)</div>
          <div class="col-4">
            252. Mangel oder „hinzunehmende Unregelmäßigkeiten“? (GE 2017 [21]
            1315)
          </div>
          <div class="col-4">
            253. Terrassen, Balkone, Loggien (GE 2019 [20] 1296)
          </div>
          <div class="col-4">
            254. Grundlagen bei der Beurteilung von „Schäden an Gebäuden“ (GE
            2019 [21] 1406)
          </div>
          <div class="col-4">
            255. Abdichtung auf WDVS-Fassade (GE 2019 [22] 1495)
          </div>
          <div class="col-4">
            256. Flüssigkunststoffabdichtung – Fußbodenbeschichtung –
            Altbau-Balkone (GE 2019 [23] 1559)
          </div>
          <div class="col-4">
            257. Verbundabdichtung „Alternative Abdichtung“ (GE 2019 [24] 1624)
          </div>
          <div class="col-4">258. Brüstungshöhen (GE 2020 [1] 44)</div>
          <div class="col-4">
            259. Fassade – Putzfassade – Denkmalschutz (GE 2020 [2] 107)
          </div>
          <div class="col-4">260. Loggien (GE 2020 [4] 248)</div>
          <div class="col-4">261. Legionellen (GE 2020 [5] 323)</div>
          <div class="col-4">
            262. Balkon-Verglasung/“Überdachung“ (GE 2020 [9] 598)
          </div>
          <div class="col-4">
            263. Bauschäden im Badezimmer (1): Schadensursache, gutachterliche
            Einstufung, Beseitigung, Vorbeugung, Lösungsdetails (GE 2020 [18]
            1174)
          </div>
          <div class="col-4">
            264. Bauschäden im Badezimmer (2): Abdichtung, allgemein (GE 2020
            [19] 1240)
          </div>
          <div class="col-4">
            265: Bauschäden im Badezimmer (3): Abdichtungsanschluss Türschwelle
            (GE 2020 [20] 1303)
          </div>
          <div class="col-4">
            266: Bauschäden im Badezimmer (4): Abdichtungsanschluss Türlaibung
            (GE 2020 [21] 1410)
          </div>
          <div class="col-4">
            267: Bauschäden im Badezimmer (5): Abdichtungsanschluss bodentiefe
            Fenster (GE 2020 [22] 1473)
          </div>
          <div class="col-4">
            268: Bauschäden im Badezimmer (6): Abdichtungsanschluss
            Revisionsöffnungen (GE 2020 [23] 1550)
          </div>
          <div class="col-4">
            269: Bauschäden im Badezimmer (7): Abdichtungen bei
            „feuchteempfindlichen Baustoffen“ (GE 2020 [24] 1613)
          </div>
          <div class="col-4">
            270: Bauschäden im Badezimmer (8): Abdichtung „Sichtestrich“ (GE
            2021 [1] 46)
          </div>
          <div class="col-4">271: Abdichtung (GE 2021 [2] 110)</div>
          <div class="col-4">
            272: Bauschäden im Badezimmer (9): „Überzähne“ –
            Höhenversatz/Überstand zwischen zwei Fliesen/Platten (GE 2021 [3]
            170)
          </div>
          <div class="col-4">
            273: Bauschäden im Badezimmer (10): Gefälleausbildung (Abdichtung im
            Gefälle) (GE 2021 [4] 234)
          </div>
          <div class="col-4">
            274: Bauschäden im Badezimmer (11): Entwässerungsrinnen im
            Duschbereich (GE 2021 [5] 301)
          </div>
          <div class="col-4">
            275: Bauschäden im Badezimmer (12): Untergrundbeschaffenheit von
            Wand und Boden [6] 364)
          </div>
          <div class="col-4">
            275: Bauschäden im Badezimmer (12): Schimmelpilzbildung (GE 2021 [9]
            558)
          </div>
          <div class="col-4">
            276: Bauschäden im Badezimmer (13): Bad-Accessoires (GE 2021 [14]
            870)
          </div>
          <div class="col-4">
            277: Bauschäden im Badezimmer (14): Elastische Verfugungen (GE 2021
            [18] 1117)
          </div>
          <div class="col-4">
            278: Bauschäden im Badezimmer (15): Statische Verfugungen (GE 2021
            [18] 1119)
          </div>
          <div class="col-4">
            279: Bauschäden im Badezimmer (16): Duschwannen (GE 2021 [19] 1184)
          </div>
          <div class="col-4">
            280: Bauschäden im Badezimmer (17): Bodengleiche Duschwannen (GE
            2021 [21] 1355)
          </div>
          <div class="col-4">281: Fallrohre (GE 2021 [22] 1422)</div>
          <div class="col-4">282: Notüberlauf (GE 2021 [22] 1423)</div>
          <div class="col-4">
            283: Abdichtung mit Bitumenbahnen (GE 2022 [2] 89)
          </div>
          <div class="col-4">
            284: Bauschäden im Badezimmer (18): Abdichtungsanschluss Badewannen
            (GE 2022 [13] 682)
          </div>
          <div class="col-4">
            285: Bauschäden im Badezimmer (19): Lage der Badewannen (GE 2022
            [14] 728)
          </div>
          <div class="col-4">
            286: Bauschäden im Badezimmer (20): Handwaschbecken/WC (GE 2022 [15]
            782)
          </div>
          <div class="col-4">
            287: Terrassen im Souterrain (GE 2022 [16] 829)
          </div>
          <div class="col-4">
            288: Bauschäden im Badezimmer (21): Revisionsöffnung (GE 2022 [19]
            997)
          </div>
          <div class="col-4">
            289: Bauschäden im Badezimmer (22): Be- und Entflüftung
            innenliegender Bäder (GE 2022 [20] 1046)
          </div>
          <div class="col-4">290: Rigolen (GE 2022 [21] 1144)</div>
          <div class="col-4">
            291: Bauschäden im Badezimmer (23): Entwässerung – Einzelablauf (GE
            2022 [24] 1302)
          </div>
          <div class="col-4">
            292: Kellernutzung = Hobbyräume (GE 2023 [1] 33)
          </div>
          <div class="col-4">
            293: Bauschäden im Badezimmer (24): Objektplanung –
            Architekten-Ausführungsplanung (GE 2023 [3] 133)
          </div>
          <div class="col-4" style="margin-top: 60px">
            <img
              src="@/assets/images/dasGrundeigentum.png"
              height="400"
              style="object-fit: contain"
            />
            
          </div>
          <div class="col-8" style="margin-top: 60px">
            <p>
              Wenn es um Baupfusch geht, gibt es in Deutschland wohl kaum einen
              brillanteren und unbarmherzigeren Analytiker als unseren
              Serien-Autor Dipl.-Ing. Joachim Schulz, der in fast jeder Ausgabe
              mit einen viel gelesenen und lehrreichen Beiträge vertreten ist.“
              <a href="https://www.grundeigentum-verlag.de/">DAS GRUNDEIGENTUM</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
</style>
