<template>
  <div>
    <section>
      <div class="container">
        <h1 :data-sub="$t('dachraumausbau.subline')">{{ $t("dachraumausbau.headline") }}</h1>
        <p v-html="$t('dachraumausbau.text.intro')"></p>
      </div>
    </section>
    <section class="highlighted">
      <div class="container">
        <h2 class="center mb-50">{{ $t("dachraumausbau.text.ablauf.headline") }}</h2>
        <div class="row noreduce steps">
          <div class="col-lg-3" v-for="(step, i) in $t('dachraumausbau.text.ablauf.steps')" :key="i">
            <div class="step">
              <i :data=" i + 1"></i>
              <span>{{ step.title }}</span>
              <p v-html="step.text"></p>
            </div>
          </div>
        </div>
        <div class="row noreduce">
          <div class="col-lg-4" v-for="(theme, i) in $t('dachraumausbau.text.ablauf.themes')" :key="i">
            <div class="step">
              <img :src="theme_images[i]" alt="gebäude">
              <span>{{ theme.title }}</span>
              <p v-html="theme.text"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <h2>{{ $t("dachraumausbau.text.objektplanung.headline") }}</h2>
        <p v-html="$t('dachraumausbau.text.objektplanung.text')"></p>
      </div>
    </section>
    <section class="highlighted">
      <div class="container">
        <h2>{{ $t("dachraumausbau.text.tragwerksplanung.headline") }}</h2>
        <p v-html="$t('dachraumausbau.text.tragwerksplanung.text1')"></p>
        <div class="row mb-50 mt-50">
          <div class="col-lg-6"><img src="/assets/images/dachraumausbau-abb1.png" alt="dachgeschoss"></div>
          <div class="col-lg-6"><img src="/assets/images/dachraumausbau-abb2.png" alt="dachgeschoss"></div>
        </div>
        <p v-html="$t('dachraumausbau.text.tragwerksplanung.text2')"></p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      theme_images: [
        "/assets/images/trittschall.jpg",
        "/assets/images/dämmung.jpg",
        "/assets/images/brandschutz.jpg"
      ]
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";

.steps {
  padding: 20px 0; 
}

.step {
  display: flex;
  flex-direction: column;
  i {
    display: inline-flex;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $color_bold;
    color: white;
    box-shadow:  $shadow;
    position: relative;
    margin: 0 auto;

    &:before {
      content: attr(data);
      display: inline-block;
      width: 100%;
      text-align: center;
      margin: auto 0;
      font-size: 1.5rem;
      font-style: normal;
    }
  }  
  span {
    margin: 20px 0;
    font-size: 1.4rem;
  }
  p {
    text-align: justify;
  }
}
</style>
