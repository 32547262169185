<template>
  <div>
    <section>
      <div class="container">
        <h1>Impressum</h1>
        <h2>Betreiber</h2>
        <p class="center">
          <b>IGS Ingenieur-Gesellschaft mbH Dipl.-Ing. Joachim Schulz</b>
          <br />Ulmenallee 53 <br />14050 Berlin <br />Geschäftsführung:
          Dipl.-Ing. Joachim Schulz <br />
          <br />Sitz: Berlin <br />Handelsregister: Berlin Amtsgericht
          Charlottenburg, HRB 23771 <br />USt-IdNr.: DE155532200 <br />
        </p>
        <h2>Kontakt</h2>
        <p class="center">
          Tel.: 030 – 300 98 30
          <br />Email: info@igs-schulz.de
        </p>
        <h2>Weitere Hinweise</h2>
        <p class="center">
          Dipl.-Ing. Joachim Schulz ist Architekt und Mitglied der
          Architektenkammer Berlin, Nr. 09436<br />
          <a
            href="https://www.ak-berlin.de/architektenkammer-berlin/regelwerke.html"
            >https://www.ak-berlin.de/architektenkammer-berlin/regelwerke.html</a
          ><br /><br />
          Dipl.-Ing. Joachim Schulz ist Beratender Ingenieur und Mitglied der
          Baukammer Berlin, Nr. 094<br />
          <a
            href="http://www.baukammerberlin.de/recht/gesetze-und-verordnungen/"
            >http://www.baukammerberlin.de/recht/gesetze-und-verordnungen/</a
          ><br /><br />
          Öffentlich bestellte und vereidigte Sachverständige sind nach Maßgabe
          von § 36 GewO tätig.<br />
          Dipl.-Ing. Joachim Schulz ist öffentlich bestellter und vereidigter
          (ö.b.u.v.) Sachverständiger für die Sachgebiete Schäden an Gebäuden,
          insb. Beton-Schäden,<br />
          Beton-Instandsetzung, WU-Beton, Sichtbeton; vereidigt durch die IHK
          Berlin, Fasanenstraße 45, 10623 Berlin.<br />
          Er unterliegt den Bestimmungen der Sachverständigenordnung der IHK
          Berlin. Diese kann unter
          <a href="https://www.ihk-berlin.de">https://www.ihk-berlin.de</a>
          eingesehen werden.
        </p>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "@/assets/variables.scss";
h2 {
  text-align: center;
}
</style>
